import ApprovalTwoToneIcon from '@mui/icons-material/ApprovalTwoTone';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Slide,
  styled,
  Typography
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, ReactElement, Ref, useEffect, useState } from 'react';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AvatarInfo = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.info.lighter};
      color: ${theme.colors.info.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonInfo = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.info.main};
     color: ${theme.palette.info.contrastText};

     &:hover {
        background: ${theme.colors.info.dark};
     }
    `
);

function ConfirmDialog(props: {
  message?: string;
  id?: string;
  open: boolean;
  onClose: () => void;
  onConfirm: (id?: string) => void;
  confirmText?: string;
  cancelText?: string;
}) {
  const [open, setOpen] = useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const closeConfirm = () => {
    setOpen(false);
    props.onClose();
  };

  const handleCompleted = () => {
    props.onConfirm(props.id);
    setOpen(false);
  };

  return (
    <DialogWrapper
      open={open}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      onClose={closeConfirm}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        p={5}
      >
        <AvatarInfo>
          <ApprovalTwoToneIcon />
        </AvatarInfo>

        <Typography
          align="center"
          sx={{
            py: 4,
            px: 6
          }}
          variant="h3"
        >
          {props.message}
        </Typography>

        <Box>
          <Button
            variant="text"
            size="large"
            sx={{
              mx: 1
            }}
            onClick={closeConfirm}
          >
            {props.cancelText || 'Cancel'}
          </Button>
          <ButtonInfo
            onClick={handleCompleted}
            size="large"
            sx={{
              mx: 1,
              px: 3
            }}
            variant="contained"
          >
            {props.confirmText || 'Delete'}
          </ButtonInfo>
        </Box>
      </Box>
    </DialogWrapper>
  );
}

export default ConfirmDialog;
