import useAuth from 'src/ui-bloom/hooks/useAuth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { hideAppLoader } from 'src/store/slices/app';

function AuthCallback() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useAuth();

  useEffect(() => {
    console.log(auth.user);
    if (auth.isAuthenticated) {
      dispatch(hideAppLoader());
      navigate('/', { replace: true });
    }
  }, []);

  return <></>;
}

export default AuthCallback;
