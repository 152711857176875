import { useNavigate, useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { SnackbarProvider } from 'notistack';

import { CssBaseline } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import oAuth from './auth-service';
import safeRouter from './router/safe-router';
import cdnService from './services/cdnService';
import ThemeProvider from './ui-bloom/theme/ThemeProvider';
import readQueryString from './utility/readQueryString';
import useAuth from './ui-bloom/hooks/useAuth';
import { getActiveTracker, getUserContext } from './services/apiService';
import { setActiveTracker, setUserContext } from './store/slices/app';
import { UserContext } from './services/apiService/response-models';
import { QueryClientProvider } from 'react-query';
import { appQueryClient } from './services/query-client';

function App() {
  const content = useRoutes(router);
  const dispatch = useDispatch();
  const _safeRouter = useRoutes(safeRouter);
  const [isAuthInProgress, setIsAuthInProgress] = useState(true);
  const [isSafeRoute, setIsSafeRoute] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  // const [brand, setBrand] = React.useState<Restaurant | undefined>();

  const SAFE_ROUTES = [
    '/signin-oidc',
    '/un-authorized',
    '/not-found',
    '/sign-up'
  ];

  function hideLoader(): void {
    document.getElementById('app-loader')?.remove();
  }

  function navigateToNotFound(): void {
    setIsSafeRoute(true);
    if (
      window.location.pathname.indexOf('/not-found') !== 0 &&
      window.location.pathname.indexOf('/un-authorized') !== 0
    )
      window.location.href =
        window.location.protocol + '//' + window.location.host + '/not-found';
  }

  function navigateToNotApproved(): void {
    if (window.location.pathname.indexOf('/status/404') !== 0)
      navigate('/status/404');
  }

  function navigateToHomeIf404(): void {
    if (window.location.pathname.indexOf('/status/404') == 0) navigate('/');
  }
  function navigateToUnAuth(): void {
    setIsSafeRoute(true);
    if (window.location.pathname.indexOf('/un-authorized') !== 0)
      window.location.href =
        window.location.protocol +
        '//' +
        window.location.host +
        '/un-authorized';
  }

  React.useEffect(() => {
    const tenantCode = 'coe-index-app';

    cdnService.init(tenantCode);

    console.log(process.env.REACT_APP_VER, 'ver 0.4 status/404');

    hideLoader();
  }, []);

  useEffect(() => {
    if (auth.isInitialized && !auth.isAuthenticated) {
      auth.loginWithPopup().then(() => {});
    }

    (async () => {
      if (auth.isInitialized && auth.isAuthenticated) {
        let userCtx: UserContext | null = null;
        try {
          const res = await getUserContext();
          userCtx = res.data;
          dispatch(setUserContext({ user: userCtx }));
        } catch (ex) {
          console.log(ex);
        }

        if (!userCtx) {
          navigateToNotApproved();
          setIsAuthInProgress(false);
          return;
        }

        try {
          const res = await getActiveTracker();
          dispatch(setActiveTracker({ tracker: res.data || null }));
        } catch (ex) {
          console.log(ex);
        }
        setIsAuthInProgress(false);
        navigateToHomeIf404();
      }
    })();
  }, [auth.isInitialized]);

  const sessionOut = async (): Promise<void> => {
    try {
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <QueryClientProvider client={appQueryClient}>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <CssBaseline />
            {isAuthInProgress ? (
              <h3>Authenticating...</h3>
            ) : auth.isInitialized ? (
              content
            ) : (
              _safeRouter
            )}
          </SnackbarProvider>
        </QueryClientProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
