import { FC, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Breadcrumbs,
  Box,
  Grid,
  Link,
  Typography,
  Tooltip,
  Button,
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import PropTypes from 'prop-types';
import ClassTwoToneIcon from '@mui/icons-material/ClassTwoTone';
import { Survey } from 'src/services/apiService/response-models';
import ConfirmDialog from 'src/components/confirm-dailog';
import { RootState, useDispatch, useSelector } from 'src/store';
import { startEval } from 'src/services/apiService';
import { setActiveTracker } from 'src/store/slices/app';
import { parseToApiErrorMessage } from 'src/utility/parseToApiErrorMessage';
import { useSnackbar } from 'notistack';

interface PageHeaderProps {
  product: Survey;
}

const PageHeader: FC<PageHeaderProps> = ({ product }) => {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { activeTracker } = useSelector((state: RootState) => state.app);

  const handleBack = (): void => {
    return navigate(`/${location.pathname.split('/')[1]}/dashboard`);
  };

  const startEvalReq = () => {
    setOpen(true);
  };

  const goToActiveSession = () => {
    if (activeTracker) {
      navigate(`/${location.pathname.split('/')[1]}/active-eval`);
    }
  };
  const startEvalConfirm = async () => {
    setOpen(false);
    setIsProcessing(true);
    try {
      if (activeTracker == null) {
        enqueueSnackbar(t('Please wait...'), { variant: 'info' });
        const res = await startEval(product.surveyID);
        dispatch(
          setActiveTracker({ tracker: { tracker: res.data, factEvals: [] } })
        );
        navigate(`/${location.pathname.split('/')[1]}/active-eval`);
      }
    } catch (ex) {
      console.log(ex);
      const msg = parseToApiErrorMessage(ex, `Failed to start evaluation`);
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
    setIsProcessing(false);
  };
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Tooltip arrow placement="top" title={t('Go back')}>
            <IconButton
              onClick={handleBack}
              color="primary"
              sx={{
                p: 2,
                mr: 2
              }}
            >
              <ArrowBackTwoToneIcon />
            </IconButton>
          </Tooltip>
          <Box>
            <Typography
              variant={mobile ? 'h5' : 'h3'}
              component={mobile ? 'h5' : 'h3'}
              gutterBottom
            >
              {t('Product Details')}
            </Typography>
            <Breadcrumbs maxItems={2} aria-label="breadcrumb">
              <Link color="inherit" href="#">
                {t('Dashboard')}
              </Link>
              <Typography color="text.primary">{product.surveyName}</Typography>
            </Breadcrumbs>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        {activeTracker == null ? (
          <Button
            sx={{
              my: { xs: 1, sm: 2 }
            }}
            size={mobile ? 'small' : 'large'}
            startIcon={<ClassTwoToneIcon />}
            onClick={startEvalReq}
            variant="contained"
            disabled={isProcessing}
          >
            {t('Start Evaluation')}
          </Button>
        ) : (
          <Button
            sx={{
              mt: { xs: 2, sm: 2 }
            }}
            size={mobile ? 'small' : 'large'}
            color="secondary"
            startIcon={<ClassTwoToneIcon />}
            onClick={goToActiveSession}
            variant="contained"
          >
            {t('Go to Active Session')}
          </Button>
        )}
      </Grid>
      <ConfirmDialog
        open={open}
        message="Are you sure you want to start evaluation?"
        cancelText="No"
        confirmText="Yes"
        onClose={() => setOpen(false)}
        onConfirm={startEvalConfirm}
      />
    </Grid>
  );
};

PageHeader.propTypes = {
  // @ts-ignore
  product: PropTypes.object.isRequired
};

export default PageHeader;
