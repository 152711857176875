import axios, { AxiosResponse } from 'axios';
import { Dictionary } from 'lodash';
import { ApiUrls } from '../endpoints';
import { ActiveTracker, FactEval, Survey, SurveyGroup, Tracker, UserContext } from './response-models';

export const getAllSurveyGroups = async (): Promise<
  AxiosResponse<SurveyGroup[]>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/survey-groups';
  const res = await axios.get(dataUrl);
  if (res && res.data) {
    (res.data as SurveyGroup[]).forEach(o => {
      o.surveys.forEach(s => {
        if (!s.surveyGroup) {
          s.surveyGroup = { ...o };
        }
      })
    })
  }

  return res;
};

export const getSurveyDetailById = (surveyId: number): Promise<
  AxiosResponse<Survey>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/survey-detail/id/' + surveyId;
  return axios.get(dataUrl);
};

export const startEval = (surveyId: number): Promise<
  AxiosResponse<Tracker>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/start-tracker';
  return axios.post(dataUrl, { surveyId });
};

export const updateEval = (tracker: Tracker): Promise<
  AxiosResponse<Tracker>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/update-tracker';
  return axios.post(dataUrl, tracker);
};

export const endEval = (trackerID: number): Promise<
  AxiosResponse<Tracker>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/end-tracker';
  return axios.post(dataUrl, { trackerID });
};



export const saveEvalAnswer = (fact: FactEval): Promise<
  AxiosResponse<Tracker>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/eval-answer';
  return axios.post(dataUrl, fact);
};

export const deleteEvalAnswer = (fact: FactEval): Promise<
  AxiosResponse<Tracker>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/delete-eval-answer';
  return axios.post(dataUrl, fact);
};
export const getActiveTracker = (): Promise<
  AxiosResponse<ActiveTracker>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/get-active-tracker';
  return axios.get(dataUrl);
};
export const getTrackers = (): Promise<
  AxiosResponse<Tracker[]>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/trackers';
  return axios.get(dataUrl);
};
export const getUserContext = (): Promise<
  AxiosResponse<UserContext>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/user-context';
  return axios.get(dataUrl);
};

