import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import { User } from 'src/models/user';
import { auth0Config } from 'src/config';
import PropTypes from 'prop-types';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { useDispatch } from 'react-redux';
import { hideAppLoader } from 'src/store/slices/app';
import {
  CompanyMain,
  defaultCompany
} from 'src/services/apiService/response-models';
import axios from 'axios';

let auth0Client: Auth0Client | null = null;

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  tenantCode: string;
  company: CompanyMain | null;
}

export interface AuthContextValue extends AuthState {
  method: 'Auth0';
  loginWithPopup: (options?: any) => Promise<void>;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    company: CompanyMain;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  tenantCode: '',
  company: defaultCompany
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user, company: comapany } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      company: comapany
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'Auth0',
  loginWithPopup: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const dispatch2 = useDispatch();

  const isCallbackPath = () => window.location.pathname == '/auth/callback';

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        auth0Client = new Auth0Client({
          redirect_uri: window.location.origin + '/auth/callback',
          screen_hint: 'login',
          client_id: auth0Config.client_id,
          domain: auth0Config.domain,
          audience: process.env.REACT_APP_COE_INDEX_SERVICE_ENDPOINT,
          scope: 'openid profile email email_verified offline_access'
        });

        await auth0Client.checkSession();

        if (isCallbackPath()) {
          try {
            await auth0Client.handleRedirectCallback();
          } catch (err) {
            console.log('Error parsing redirect:', err);
          }
        }
        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
          const user = await auth0Client.getUser();
          //  console.log('auth0Client', user);
          const token = await auth0Client?.getTokenSilently();
          console.log(token);
          axios.defaults.headers['Authorization'] = 'Bearer ' + token;
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: {
                id: 1,
                jobtitle: user.nickname,
                avatar: user.picture,
                email: user.email,
                name: user.name,
                role: user.role,
                location: user.location,
                username: user.username,
                posts: user.posts,
                coverImg: user.coverImg,
                followers: user.followers,
                description: user.description
              },
              company: defaultCompany
            }
          });
          dispatch2(hideAppLoader());
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: null,
              company: defaultCompany
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            company: defaultCompany
          }
        });
      }
    };

    initialize();
  }, []);

  const loginWithPopup = async (options): Promise<void> => {
    const isAuthenticated = await auth0Client?.isAuthenticated();

    if (!isAuthenticated && window.location.pathname != '/auth/callback') {
      await auth0Client.loginWithRedirect(options);
    }

    if (isAuthenticated) {
      const user = await auth0Client.getUser();
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            id: 1,
            jobtitle: 'Lead Developer',
            avatar: user.picture,
            email: user.email,
            name: user.name,
            role: user.role,
            location: user.location,
            username: user.username,
            posts: user.posts,
            coverImg: user.coverImg,
            followers: user.followers,
            description: user.description
          }
        }
      });
    }
  };

  const logout = (): void => {
    auth0Client.logout({ returnTo: window.location.origin });
    dispatch({
      type: 'LOGOUT'
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Auth0',
        loginWithPopup,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
