import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

function PageHeader() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Typography
        align="center"
        variant={mobile ? 'h4' : 'h1'}
        component={mobile ? 'h4' : 'h3'}
        sx={{
          mb: 1
        }}
      >
        {t('Eval Center')}
      </Typography>
      <Typography
        align="center"
        variant={mobile ? 'h4' : 'h1'}
        fontWeight="normal"
        color="text.secondary"
      >
        {t('Find survey matching your skillset & expertise')}
      </Typography>
    </>
  );
}

export default PageHeader;
