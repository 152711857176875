import React from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import BaseLayout from 'src/ui-bloom/layouts/BaseLayout';
import NotFound from '../contents/not-found';
import baseRoutes from './base';

import AccountDeletion from '../pages/account-deletion';
import BoxedSidebarLayout from '../ui-bloom/layouts/BoxedSidebarLayout';

import EvalMain from 'src/contents/eval-main';
import DashboardMain from 'src/contents/DashboardMain';
import ProductDetailView from 'src/contents/ProductDetail';
import AuthCallback from 'src/contents/auth-callback';
import ApplicationSetting from 'src/contents/settings';
import EvalSuccess from 'src/contents/eval-success';
import Status404 from 'src/contents/status-404';
import CollapsedSidebarLayout from 'src/ui-bloom/layouts/CollapsedSidebarLayout';

const router: RouteObject[] = [
  {
    path: '',
    element: <Navigate to="app" />
  },
  {
    path: 'app',
    element: <BaseLayout />,
    children: baseRoutes
  },
  // Extended Sidebar Layout
  {
    path: 'auth/callback',
    element: <AuthCallback />
  },
  {
    path: 'app',
    element: <CollapsedSidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" />
      },

      {
        path: 'dashboard',
        children: [
          {
            path: '',
            element: <DashboardMain />
          }
        ]
      },
      {
        path: 'active-eval',
        element: <EvalMain />
      },
      {
        path: 'eval-success',
        element: <EvalSuccess />
      },
      {
        path: 'product-details/:productId',
        element: <ProductDetailView />
      },
      {
        path: 'setting',
        element: <ApplicationSetting />
      },

      {
        path: 'not-found',
        element: <NotFound />
      },
      {
        path: '*',
        element: <Navigate to="not-found" />
      }
    ]
  },
  {
    path: 'status/404',
    element: <Status404 />
  },
  {
    path: 'app/delete-account',
    element: <AccountDeletion />
  }
];

export default router;
