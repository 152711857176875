import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { AppState } from '../app-state';
import { ActiveTracker, SurveyGroup, Tracker, UserContext } from 'src/services/apiService/response-models';

const initialState: AppState = {
  sidebarOpen: false,
  isDrawerOpen: false,
  activeTracker: null,
  userCtx: null
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {

    setActiveTracker(
      state: AppState,
      action: PayloadAction<{ tracker: ActiveTracker }>
    ) {
      const { tracker } = action.payload;
      state.activeTracker = _.cloneDeep(tracker);
    },
    setUserContext(
      state: AppState,
      action: PayloadAction<{ user: UserContext }>
    ) {
      const { user } = action.payload;
      state.userCtx = { ...user };
    },
    openSidebar(state: AppState): void {
      state.sidebarOpen = true;
    },
    closeSidebar(state: AppState): void {
      state.sidebarOpen = false;
    },
    openDrawerPanel(state: AppState): void {
      state.isDrawerOpen = true;
    },
    closeDrawerPanel(state: AppState): void {
      state.isDrawerOpen = false;
    },
    hideAppLoader(state: AppState): void {
      //(document.getElementsByClassName('main_app_loader_container') as any)[0].style.display = 'none';
      state.appLoaderVisible = false;
    },
  }
});

const { actions, reducer } = slice;

export const {
  closeSidebar,
  openSidebar,
  openDrawerPanel,
  closeDrawerPanel,
  hideAppLoader,
  setActiveTracker,
  setUserContext
} = actions;
export default reducer;
