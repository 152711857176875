import { useState, useCallback, useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';

import { Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import ProductBody from './ProductBody';
import Footer from 'src/ui-bloom/components/Footer';
import PageHeader from './PageHeader';
import { getSurveyDetailById } from 'src/services/apiService';
import { Survey } from 'src/services/apiService/response-models';

function ProductDetailView() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [product, setProduct] = useState<Survey | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  // @ts-ignore
  const { productId } = useParams();

  useEffect(() => {
    setIsLoading(true);
    getSurveyDetailById(Number(productId)).then((o) => {
      setProduct(o.data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading || !product) {
    return (
      <Grid
        sx={{
          px: mobile ? 1 : 4,
          mt: mobile ? 1 : 2
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={mobile ? 1 : 4}
      >
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={80} width={'100%'} />
        </Grid>

        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={300} width={'100%'} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={300} width={'100%'} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={300} width={'100%'} />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Helmet>
        <title>{product.surveyName + ' - Product Details'}</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader product={product} />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: mobile ? 1 : 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={mobile ? 1 : 4}
      >
        <Grid item xs={12}>
          <ProductBody product={product} />
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default ProductDetailView;
