import {
  Grid,
  Typography,
  Avatar,
  useTheme,
  Box,
  styled,
  alpha,
  lighten,
  Card,
  Divider,
  Stack,
  Button,
  IconButton,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import PieChartTwoToneIcon from '@mui/icons-material/PieChartTwoTone';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import ChevronLeftTwoToneIcon from '@mui/icons-material/ChevronLeftTwoTone';
import { RootState, useSelector } from 'src/store';
import { Product } from 'src/ui-bloom/models/dashboards';
import { Survey } from 'src/services/apiService/response-models';
import { useContext } from 'react';
import EvalContext, { EvalContextType } from './EvalContext';
import { useSnackbar } from 'notistack';
const AvatarPageTitle = styled(Avatar)(
  ({ theme }) => `
    
      color: ${theme.colors.primary.main};
      margin-right: ${theme.spacing(2)};
      background: ${
        theme.palette.mode === 'dark'
          ? theme.colors.alpha.trueWhite[10]
          : theme.colors.alpha.white[50]
      };
      box-shadow: ${
        theme.palette.mode === 'dark'
          ? '0 1px 0 ' +
            alpha(lighten(theme.colors.primary.main, 0.8), 0.2) +
            ', 0px 2px 4px -3px rgba(0, 0, 0, 0.3), 0px 5px 16px -4px rgba(0, 0, 0, .5)'
          : '0px 2px 4px -3px ' +
            alpha(theme.colors.alpha.black[100], 0.4) +
            ', 0px 5px 16px -4px ' +
            alpha(theme.colors.alpha.black[100], 0.2)
      };
`
);

const DotLegend = styled('span')(
  ({ theme }) => `
    border-radius: 22px;
    width: ${theme.spacing(1.8)};
    height: ${theme.spacing(1.8)};
    display: inline-block;
    margin-right: ${theme.spacing(0.8)};
    border: ${theme.colors.alpha.white[100]} solid 2px;
`
);

const ButtonWrapper = styled(Button)(
  ({ theme }) => `
      background: ${theme.colors.alpha.black[10]};
      border-color: ${theme.colors.alpha.black[30]};
      transition: ${theme.transitions.create(['all'])};
      color: ${theme.colors.alpha.black[100]};
      border-radius: 50px;
  
      &:hover {
        background: ${alpha(theme.colors.alpha.black[100], 0.2)};
        border-color: ${theme.colors.alpha.black[30]};
        color: ${theme.colors.alpha.black[100]};
      }
  `
);

function PageHeader(props: { title: string; subTitle: string }) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const context = useContext(EvalContext);

  const remainCount = context.product.questions.length - context.answers.length;

  const moveToRem = (i: number) => {
    const questions = context.product.questions;
    if (i == -1) {
      //prev remaining
      let index = -1;
      for (let i = context.activeIndex - 1; i >= 0; i--) {
        if (index == -1 && i < questions.length) {
          if (
            !context.answers.find(
              (a) => a.questionID == questions[i].questionID
            )
          ) {
            index = i;
            break;
          }
        }
      }
      if (index != -1) {
        context.setActiveIndex(index);
      } else {
        enqueueSnackbar(t(`No previous question is remaining`), {
          variant: 'info'
        });
      }
    } else {
      //next

      let index = -1;
      for (let i = context.activeIndex + 1; i < questions.length; i++) {
        if (index == -1 && i < questions.length) {
          if (
            !context.answers.find(
              (a) => a.questionID == questions[i].questionID
            )
          ) {
            index = i;
            break;
          }
        }
      }
      if (index != -1) {
        context.setActiveIndex(index);
      } else {
        enqueueSnackbar(t(`No next question is remaining`), {
          variant: 'info'
        });
      }
    }
  };
  return (
    <Box
      display="flex"
      alignItems={{ xs: 'stretch', md: 'center' }}
      flexDirection={{ xs: 'column', md: 'row' }}
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center">
        <AvatarPageTitle
          variant="rounded"
          sx={{
            width: theme.spacing(mobile ? 4 : 8),
            height: theme.spacing(mobile ? 4 : 8)
          }}
        >
          <PieChartTwoToneIcon fontSize={mobile ? 'small' : 'large'} />
        </AvatarPageTitle>
        <Box>
          <Typography
            variant={mobile ? 'h5' : 'h3'}
            component={mobile ? 'h5' : 'h3'}
            gutterBottom
          >
            {t(props.title || 'Survey')}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              props.subTitle || 'Build any kind of survey for any kind of niche'
            )}
          </Typography>
        </Box>
      </Box>
      <Card
        sx={{
          mt: { xs: 1, lg: 0 },
          px: { xs: 1, md: 2 }
        }}
      >
        <Stack
          direction="row"
          divider={
            <Divider
              sx={{
                background: `${theme.colors.alpha.black[10]}`
              }}
              orientation="vertical"
              flexItem
            />
          }
          justifyContent="space-around"
          alignItems="center"
          spacing={1}
          padding={mobile ? 1 : 2}
        >
          <Box textAlign="center">
            <Typography gutterBottom variant={mobile ? 'caption' : 'h4'}>
              Total
            </Typography>
            <Typography color="text.secondary" variant={mobile ? 'h5' : 'h4'}>
              {context.product.questions.length}
            </Typography>
          </Box>
          <Box textAlign="center" justifyItems={'top'}>
            <Typography variant={mobile ? 'caption' : 'h4'} gutterBottom>
              {t('Answered')}
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="center">
              {context.answers.length > 0 ? (
                <>
                  <DotLegend
                    style={{
                      animation: `pulse 1s infinite`,
                      background: `${theme.colors.success.main}`
                    }}
                  />
                  <Typography
                    color="text.primary"
                    variant={mobile ? 'h5' : 'h4'}
                  >
                    {context.answers.length}
                  </Typography>
                </>
              ) : (
                <Typography color="text.primary" variant={mobile ? 'h5' : 'h4'}>
                  0
                </Typography>
              )}
            </Box>
          </Box>
          <Box textAlign="center">
            <Stack sx={{ width: '100%' }}>
              <Typography gutterBottom variant={mobile ? 'caption' : 'h4'}>
                Remaining
              </Typography>
              <Box display="flex" alignItems="center">
                {remainCount > 0 && (
                  <Tooltip placement="top" title="Previous un-answer question">
                    <IconButton
                      onClick={() => moveToRem(-1)}
                      color="primary"
                      sx={{
                        mr: 0.5,
                        p: 0.5
                      }}
                    >
                      <ChevronLeftTwoToneIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Typography
                  sx={{ width: '100%' }}
                  variant="body2"
                  color="text.secondary"
                >
                  <b className="error">{remainCount}</b>
                </Typography>
                {remainCount > 0 && (
                  <Tooltip placement="top" title="Next un-answer question">
                    <IconButton
                      onClick={() => moveToRem(1)}
                      color="primary"
                      sx={{
                        ml: 0.5,
                        p: 0.5
                      }}
                    >
                      <ChevronRightTwoToneIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              {/* <Typography color="text.secondary" variant="h5">
                {19}
              </Typography>
              <Button
                disableRipple={false}
                variant="outlined"
                sx={{}}
                endIcon={<ArrowForwardTwoToneIcon />}
                size="small"
                color="primary"
              ></Button> */}
            </Stack>

            <Box></Box>
          </Box>
        </Stack>
      </Card>
    </Box>
  );
}

export default PageHeader;
