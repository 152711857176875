import {
  Button,
  Card,
  Box,
  CardHeader,
  CardActions,
  Typography,
  Link,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Pagination,
  styled,
  Grid,
  useTheme,
  FormControl,
  Select,
  MenuItem,
  TextField,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  ListSubheader,
  IconButton,
  CircularProgress,
  useMediaQuery
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatDistance, subHours, subMinutes } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import { Question, Response } from 'src/services/apiService/response-models';
import EvalContext from '../EvalContext';
import NavigateBeforeTwoToneIcon from '@mui/icons-material/NavigateBeforeTwoTone';
import NavigateNextTwoToneIcon from '@mui/icons-material/NavigateNextTwoTone';
import ConfirmDialog from 'src/components/confirm-dailog';
import { endEval } from 'src/services/apiService';
const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
  `
);

const BoxWrapper = styled(Box)(
  ({ theme }) => `
      border-radius: ${theme.general.borderRadius};
  `
);

const ButtonWrapper = styled(Button)(
  ({ theme }) => `
      color: ${theme.colors.primary.main};
      background: ${theme.colors.alpha.white[70]};
      font-weight: normal;
      
      &:hover {
        color: ${theme.colors.alpha.black[100]};
        background: ${theme.colors.alpha.white[100]};
      }
  `
);

const DividerWrapper = styled(Divider)(
  ({ theme }) => `
      background: ${theme.colors.alpha.black[10]};
  `
);

function QuestionPager(props: {
  questions: Question[];
  onFinishEval: () => void;
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const context = useContext(EvalContext);
  // const [activeIndex, setActiveIndex] = useState(1);
  const [answer, setAnswer] = useState<Response | null>(null);
  const question = props.questions[context.activeIndex];

  const [isEvalFinishOpen, setIsEvalFinishOpen] = useState(false);

  const onAnswerSelect = (e: Response) => {
    setAnswer(e ? { ...e } : null);
    if (e) {
      context.onAnswerSubmit(question.questionID, { ...e });
    } else {
      context.onAnswerClear(question.questionID);
    }
  };

  const moveTo = (i: number) => {
    if (i == -1) {
      //prev
      if (context.activeIndex > 0) {
        context.setActiveIndex(context.activeIndex - 1);
      }
    } else {
      //next
      if (context.activeIndex < context.product.questions.length) {
        context.setActiveIndex(context.activeIndex + 1);
      }
    }
  };
  const onAnswerSubmit = () => {
    if (answer) {
      context.onAnswerSubmit(question.questionID, answer);
    }
  };

  useEffect(() => {
    const match = context.answers.find(
      (o) => o.questionID == question.questionID
    );
    if (match) {
      const resMatch = question.responses.find(
        (o) => o.responseID == match.responseID
      );
      if (resMatch) {
        setAnswer({ ...resMatch });
      }
    }
  }, [context.activeIndex]);

  return (
    <>
      <Card>
        <CardHeader title={t('Question no. ' + (context.activeIndex + 1))} />
        <Divider />
        <Grid container>
          <Grid item xs={12} md={12} justifyContent={'center'}>
            <Card sx={{ p: { xs: 1, md: 4 }, py: { sx: 0, md: 2 } }}>
              {/* <Typography variant="subtitle2">
              {
                'Complete the function, solve the first integeger problem to computer the sum of two arrays'
              }
            </Typography> */}
              <Typography
                variant="h3"
                sx={{
                  py: { xs: 0, md: 1 }
                }}
                component="h3"
              >
                {question.questionShortText}
              </Typography>
              <Typography variant="subtitle2">
                {question.questionText}
              </Typography>
              {/* <Divider
                sx={{
                  my: 2
                }}
              /> */}
              <Grid container spacing={0}>
                <Grid item xs={12} sx={{ pt: 2 }}>
                  <AnswerList
                    answers={question.responses || []}
                    onSelect={onAnswerSelect}
                    selectedResponseId={answer?.responseID}
                  />
                </Grid>
                <Grid item xs={12} sx={{ pt: 2 }}>
                  <BoxWrapper
                    display="flex"
                    alignItems="stretch"
                    justifyContent="space-around"
                    p={1}
                  >
                    <Button
                      disabled={context.activeIndex == 0}
                      size="small"
                      color="primary"
                      variant="contained"
                      startIcon={<NavigateBeforeTwoToneIcon />}
                      onClick={() => moveTo(-1)}
                    >
                      {t('Prev')}
                    </Button>
                    <Button
                      disabled={
                        context.activeIndex >=
                        context.product.questions.length - 1
                      }
                      size="small"
                      color="primary"
                      variant="contained"
                      endIcon={<NavigateNextTwoToneIcon />}
                      onClick={() => moveTo(1)}
                    >
                      {t('Next')}
                    </Button>
                    {/* <Button size="small" color="primary" variant="contained">
                      {t('Previous')}
                    </Button>
                    <Button size="small" color="primary" variant="contained">
                      {t('Next')}
                    </Button> */}
                  </BoxWrapper>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <CardActions
          disableSpacing
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Pagination
            variant="outlined"
            showFirstButton
            showLastButton
            page={context.activeIndex + 1}
            onChange={(e, v) => {
              context.setActiveIndex(v - 1);
              setAnswer(null);
            }}
            shape="rounded"
            count={props.questions.length}
            defaultPage={6}
            siblingCount={0}
            boundaryCount={2}
            color="primary"
          />
        </CardActions>
      </Card>
      {context.answers.length == context.product.questions.length && (
        <Box
          py={1}
          sx={{
            textAlign: 'center',
            background: `${theme.colors.alpha.black[5]}`
          }}
        >
          <Button
            fullWidth
            disabled={context.isSubmitting}
            startIcon={
              context.isSubmitting ? <CircularProgress size="1rem" /> : null
            }
            variant="text"
            color="success"
            onClick={() => setIsEvalFinishOpen(true)}
            sx={{
              backgroundColor: `${theme.colors.success.main}`,
              textTransform: 'uppercase',
              py: 1.5,
              color: `${theme.palette.getContrastText(
                theme.colors.success.dark
              )}`,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: `${theme.colors.success.main}`,
                color: `${theme.palette.getContrastText(
                  theme.colors.success.dark
                )}`
              }
            }}
          >
            {t('Finish Evaluation')}
          </Button>
        </Box>
      )}
      <ConfirmDialog
        open={isEvalFinishOpen}
        message="Are you sure you want to submit evaluation?"
        cancelText="No"
        confirmText="Yes"
        onClose={() => setIsEvalFinishOpen(false)}
        onConfirm={props.onFinishEval}
      />
    </>
  );
}
const ListWrapper = styled(List)(
  () => `
      .MuiListItem-root {
        border-radius: 0;
        margin: 0;
      }
`
);
const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
    background-color: ${theme.colors.success.lighter};
    color: ${theme.colors.success.main};
    width: ${theme.spacing(5)};
    height: ${theme.spacing(5)};
`
);
function AnswerList(props: {
  answers: Response[];
  selectedResponseId?: number;
  onSelect: (e: Response) => void;
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [selected, setSelected] = useState<Response | null>(null);

  useEffect(() => {
    if (props.selectedResponseId) {
      const match = props.answers.find(
        (o) => o.responseID == props.selectedResponseId
      );
      setSelected({ ...match });
    } else {
      setSelected(null);
    }
  }, [props.answers, props.selectedResponseId]);

  const onSelect = (e: Response) => {
    if (selected && selected.responseID == e.responseID) {
      //clear
      setSelected(null);
      props.onSelect(null);
    } else {
      setSelected({ ...e });
      props.onSelect(e);
    }
  };

  return (
    <Card
      sx={{
        height: '100%',
        pt: { xs: 0, md: 2 }
      }}
    >
      <CardHeader title={t('Select Option')} />
      <Divider />
      {props.answers.map((o, i) => (
        <React.Fragment key={o.responseID}>
          <ListWrapper disablePadding>
            <ListItem
              onClick={() => onSelect(o)}
              selected={selected?.responseID == o.responseID}
              button
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    width: 38,
                    height: 38,
                    background: `${
                      selected?.responseID == o.responseID
                        ? theme.colors.success.main
                        : theme.colors.info.main
                    }`,
                    color: `${
                      selected?.responseID == o.responseID
                        ? theme.palette.success.contrastText
                        : theme.palette.info.contrastText
                    }`
                  }}
                >
                  {i + 1}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  variant: mobile ? 'subtitle2' : 'h5',
                  color: `${theme.colors.alpha.black[100]}`
                }}
                primary={o.responseText}
              />
              {selected?.responseID == o.responseID && (
                <AvatarSuccess
                  sx={{
                    mr: 1
                  }}
                >
                  <CheckTwoToneIcon />
                </AvatarSuccess>
              )}
            </ListItem>
            <Divider />
          </ListWrapper>
        </React.Fragment>
      ))}
    </Card>
  );
}
export default QuestionPager;
