import { createContext } from 'react';
import {
  FactEval,
  Survey,
  SurveyGroup
} from 'src/services/apiService/response-models';
import { Response } from 'src/services/apiService/response-models';

export interface EvalContextType {
  isLoading: boolean;
  product: Survey;
  answers: FactEval[];
  activeIndex: number;
  onAnswerSubmit: (q: number, ans: Response) => void;
  onAnswerClear: (q: number) => void;
  setActiveIndex: (i: number) => void;
  isSubmitting: boolean;
}

export const initialState: EvalContextType = {
  isLoading: false,
  isSubmitting: false,
  product: null,
  activeIndex: 0,
  answers: [],
  onAnswerSubmit: () => {},
  onAnswerClear: () => {},
  setActiveIndex: () => {}
};

const EvalContext = createContext<EvalContextType>(initialState);

export default EvalContext;
