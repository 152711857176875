import { Box, Card, CardHeader, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import useAuth from 'src/ui-bloom/hooks/useAuth';

function GeneralContent() {
  const { t }: { t: any } = useTranslation();
  const auth = useAuth();

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            m: 1
          }}
        >
          <CardHeader title="User account details" />
          <Box>
            <Typography
              variant="h4"
              sx={{
                m: 1
              }}
            >
              {t('User account details')}
            </Typography>
            {/* <Typography
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
              sx={{
                m: 1
              }}
            >
              {t('Enter the password to set new password.')}
            </Typography> */}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

export default GeneralContent;
